import { acceptHMRUpdate, defineStore } from 'pinia';
import { capitalize, cloneDeep, debounce, keyBy, omit, sortBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import SelectSingleLabelTemplate from '~/dashboard/components/vueform-schema-templates/select-single-label-template.vue';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import ColumnSelectorTemplate from '~/dashboard/components/vueform-schema-templates/column-selector-template.vue';
import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';

export const useDashboardFormsStore = defineStore('dashboard_forms', {
  state: () => ({
    forms_configuration: {},
    template_options: [],
    template_forms_options: [],
    is_workflow_template: null,
    forms_options: [],
    xfield_options: [],
    yvalue_options: [],
    search_query: '',
    breakdown_options: [],
    options_loading: true,
    axis_widget_types: [
      'vertical_graph',
      'vertical_graph',
      'horizontal_bar',
      'donut',
      'line',
      'area',
      'activities_table',
      'number_chart',
    ],
    ongoing_submissions_types: [
      'forms_list',
      'forms_list_with_tabs',
      'status_breakdown',
      'assignee_breakdown',
      'asset_breakdown',
      'step_history',
      'single_form_view',
    ],
    datetime_widget_types: [
      'line',
      'area',
      'number_chart',
    ],
    form_response_options: [],
    timerange_type_options: [
      ['all', 'All time'],
      ['today', 'Today'],
      ['yesterday', 'Yesterday'],
      ['last_7_days', 'Last 7 days'],
      ['next_7_days', 'Next 7 days'],
      ['last_30_days', 'Last 30 days'],
      ['next_30_days', 'Next 30 days'],
      ['this_week', 'This week'],
      ['last_week', 'Last week'],
      ['this_month', 'This month'],
      ['last_month', 'Last month'],
      ['this_quarter', 'This quarter'],
      ['last_quarter', 'Last quarter'],
      ['this_year', 'This year'],
      ['last_year', 'Last year'],
      ['week_to_date', 'Week to date'],
      ['month_to_date', 'Month to date'],
      ['quarter_to_date', 'Quarter to date'],
      ['year_to_date', 'Year to date'],
      ['custom', 'Custom'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    filter_fields: [],
    data_set: false,
    is_form_dashboard: false,
    single_form_data: [],
    draft_form_uid: false,
    disable_fields_exception: [],
    forms_v2_filters: [],
    fam_overview_display_filters_ref: null,
    container_options: [],

  }),
  getters: {
    forms_widget_type: state => state.forms_configuration?.type || null,
    active_form_id: state => state.forms_configuration?.form || null,
    active_template_id: state => state.forms_configuration?.template || null,
    active_template_form_id: state => state.forms_configuration?.template_form || null,
    type_options: (state) => {
      const auth_store = useAuthStore();
      const route = useRoute();
      return [
        ...(route?.name === 'fam-overview' && auth_store.check_split('forms_v2')
          ? [
              // ['activity_history', 'Activity history', 'See activity history. '],
              ['forms_list_with_tabs', 'List view', 'Shows a list of forms matching the filters.'],
            ]
          : []),
        ...(route?.name !== 'fam-overview'
          ? [
              ['forms_list', 'Forms List', 'Shows a list of forms matching the filters. '],
            ]
          : []),
        ['single_form_view', 'Detail view', 'Displays complete or custom information from the selected form.'],
        ['status_breakdown', 'Status Breakdown', 'Pie chart representing the proportions of forms by their statuses for a specific template and matching the filtering criteria.'],
        ['assignee_breakdown', 'Assignee breakdown', 'Vertical bar chart representing the count of forms by their assignees separately and matching the filtering criteria.'],
        ['asset_breakdown', 'Asset breakdown', 'Horizontal bar chart representing the count of forms by their asset of origin for a specific template and matching the filtering criteria.'],
        ['step_history', 'Step history', 'Horizontal bar chart displaying the count of forms grouped by their current statuses plotted against their completed steps.'],
        ...(auth_store.check_split('forms_map_widget') ? [['features_map', 'Map view', 'Map view displaying forms by their statuses and matching the filter criteria.']] : []),
        ['form_responses', 'Responses', 'Table displaying the submissions for a form template with selected fields as columns.'],
        ['number_chart', 'Metric', 'Represent a number that is aggregated across all matching forms for the selected field with comparison trends.'],
        ['vertical_graph', 'Vertical Bar Chart'],
        ['horizontal_bar', 'Horizontal Bar Chart'],
        ['donut', 'Pie Chart'],
        ['line', 'Line Chart'],
        ['area', 'Area Chart'],
        ['activities_table', 'Table chart'],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          description: item[2],
        };
      });
    },
    available_form_columns: (state) => {
      const options = state.form_response_options || {};

      if (!state.forms_configuration?.columns?.length)
        return Object.values(options);

      const res = Object.values(options);
      const selected_columns = state.forms_configuration.columns.map(c => c?.key);
      res.forEach((option) => {
        option.disabled = selected_columns.includes(option.key);
      });

      return res;
    },
    field_options(state) {
      if (state.datetime_widget_types.includes(this.forms_widget_type))
        return state.xfield_options.filter(
          o => o.type === 'datetime' || o.type === 'date',
        );
      return state.xfield_options;
    },
    has_timeseries: (state) => {
      if (state.datetime_widget_types.includes(state.forms_configuration.type))
        return true;
      else if (['datetime', 'date'].includes(state.forms_configuration?.field?.type))
        return state.forms_configuration.type === 'vertical_graph';

      return false;
    },
    timerange_interval_options: (state) => {
      let options = [];
      switch (state.forms_configuration.timerange_type) {
        case 'all':
        case 'custom':
          options = ['daily', 'weekly', 'monthly', 'quarterly'];
          break;
        case 'today':
        case 'yesterday':
        case 'last_7_days':
        case 'next_7_days':
        case 'this_week':
        case 'last_week':
        case 'week_to_date':
          options = ['daily'];
          break;
        case 'last_30_days':
        case 'next_30_days':
        case 'this_month':
        case 'last_month':
        case 'month_to_date':
          options = ['daily', 'weekly'];
          break;
        case 'this_quarter':
        case 'last_quarter':
        case 'quarter_to_date':
          options = ['weekly', 'monthly'];
          break;
        case 'this_year':
        case 'last_year':
        case 'year_to_date':
          options = ['monthly', 'quarterly'];
          break;
        default:
          options = [];
      };

      return options.map(item => ({
        value: item,
        label: capitalize(item === 'daily' ? 'day' : item.substring(0, item.length - 2)),
      }));
    },
    summary_options() {
      const has_number_fields = this.xfield_options.find(o => o.type === 'number');
      return [
        ['none', 'None'],
        ...(has_number_fields !== undefined
          ? [
              ['sum', 'Sum'],
              ['avg', 'Average'],
              ['min', 'Minimum'],
              ['max', 'Maximum'],
            ]
          : []),
        ['count', 'Count'],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
    },
    value_options(state) {
      if (state.forms_configuration.summary === 'none')
        return state.yvalue_options;
      return (
        state.yvalue_options?.filter(
          val => val.agg === state.forms_configuration.summary,
        ) || []
      );
    },
    forms_schema(state) {
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: this.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.forms_configuration.type || this.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        form: {
          type: 'select',
          items: state.forms_options,
          search: true,
          native: false,
          label: 'Form',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          slots: {
            'option': SelectOptionTemplate,
            'single-label': SelectSingleLabelTemplate,
          },
          conditions: [
            function (form$) {
              if (state.is_form_dashboard)
                return false;

              return !['forms_list', 'forms_list_with_tabs', 'single_form_view', 'activity_history', 'features_map', null].includes(form$.el$('type')?.value);
            },
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'not_in',
                  [
                    'forms_list',
                    'forms_list_with_tabs',
                    'single_form_view',
                    'activity_history',
                    null,
                  ],
                ],
              ],
            },
          ],
          default: state.forms_configuration.form || state.forms_options?.[0]?.value || null,
        },
        template: {
          type: 'select',
          items: state.template_options,
          search: true,
          native: false,
          label: 'Template',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          disabled: state.is_form_dashboard,
          slots: {
            'option': SelectOptionTemplate,
            'single-label': SelectSingleLabelTemplate,
          },
          conditions: [
            [
              'type',
              'in',
              [
                'single_form_view',
                'features_map',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'single_form_view',
                    'activity_history',
                    'features_map',

                  ],
                ],
              ],
            },
          ],
          default: state.forms_configuration.template || state.template_options?.[0]?.value || null,
        },
        template_form: {
          type: 'select',
          items: state.template_forms_options,
          search: true,
          native: false,
          label: 'Form',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          onSearchChange(query) {
            debounce(() => {
              this.search_query = query;
            }, 400,
            );
          },
          conditions: [
            [
              'type',
              'in',
              [
                'single_form_view',

              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'single_form_view',

                  ],
                ],
              ],
            },
          ],
          slots: {
            'option': SelectOptionTemplate,
            'single-label': SelectSingleLabelTemplate,
          },
          default: state.forms_configuration.template_form || state.template_forms_options?.[0]?.value || null,
        },
        field: {
          type: 'form-field-tree',
          label: 'Field',
          options: {
            options: {
              name: 'field_select',
            },
            data: this.field_options,
            is_workflow: this.is_workflow_template,
            options_loading: state?.options_loading,
          },
          conditions: [
            [
              'type',
              'in',
              state.axis_widget_types,
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  state.axis_widget_types,
                ],
              ],
            },
          ],
          default: state.forms_configuration.field || this.field_options?.[0] || null,
        },
        summary: {
          type: 'select',
          items: this.summary_options,
          search: true,
          native: false,
          label: 'Summary',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              state.axis_widget_types,
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  state.axis_widget_types,
                ],
              ],
            },
          ],
          default: state.forms_configuration.summary || 'count',
        },
        value: {
          type: 'form-field-tree',
          label: 'Value',
          options: {
            options: {
              name: 'value_select',
              val: `${(state?.forms_configuration?.value || this.value_options?.[0])?.label?.split(' ')?.join('_') || 'test'}`,
            },
            data: this.value_options,
            is_workflow: this.is_workflow_template,
            options_loading: state?.options_loading,
          },
          conditions: [
            [
              'type',
              'in',
              state.axis_widget_types,
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  state.axis_widget_types,
                ],
              ],
            },
          ],
          default: state.forms_configuration.value || this.value_options?.[0] || null,
        },
        breakdown: {
          type: 'form-field-tree',
          label: 'Group by',
          options: {
            options: {
              name: 'breakdown_select',
            },
            data: this.breakdown_options,
            is_workflow: this.is_workflow_template,
            options_loading: state?.options_loading,
          },
          conditions: [
            [
              'type',
              'in',
              state.axis_widget_types,
            ],
            [
              'type',
              'not_in',
              ['number_chart', 'donut'],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [state.axis_widget_types],
                ],
                [
                  'type',
                  'not_in',
                  ['number_chart', 'donut'],
                ],
              ],
            },
          ],
          default: state.forms_configuration.breakdown || this.breakdown_options?.[0] || null,
        },
        columns: {
          type: 'static',
          label: 'Columns',
          rules: [
            'distinct',
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'form_responses',
                  ],
                ],
              ],
            },
          ],
          conditions: [
            [
              'type',
              'in',
              [
                'form_responses',
              ],
            ],
          ],
          slots: {
            default: ColumnSelectorTemplate,
          },
        },
        container: {
          type: 'select',
          items: state.container_options,
          search: true,
          native: false,
          label: 'View',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'features_map',

              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'features_map',

                  ],
                ],
              ],
            },
          ],
          default: state.forms_configuration.container || state.container_options?.[0]?.value || null,
        },
        display_previous_value: {
          type: 'checkbox',
          text: 'Compare with previous range',
          conditions: [
            [
              'type',
              'in',
              [
                'number_chart',
              ],
            ],
          ],
          default: state.forms_configuration.display_previous_value || false,
        },
        prettify_values: {
          type: 'checkbox',
          text: 'Prettify values',
          conditions: [
            [
              'type',
              'in',
              [
                'number_chart',
              ],
            ],
          ],
          default: state.forms_configuration.prettify_values || false,
        },
        inverse_trend: {
          type: 'checkbox',
          text: 'Inverse positive/negative',
          conditions: [
            [
              'type',
              'in',
              [
                'number_chart',
              ],
            ],
          ],
          default: state.forms_configuration.inverse_trend || false,
        },
        scope: {
          type: 'checkbox',
          text: 'Make report data specific to the viewer',
          default: state.forms_configuration.scope || false,
          conditions: [
            [
              'type',
              'not_in',
              [
                'single_form_view',
                'forms_list',
                'forms_list_with_tabs',
                'activity_history',
                'features_map',
              ],
            ],
          ],
        },
        cumulative: {
          type: 'checkbox',
          text: 'Show cumulative charts',
          conditions: [
            [
              'type',
              'in',
              [
                'vertical_graph',
              ],
            ],
          ],
          default: state.forms_configuration.cumulative || false,
        },
        transpose: {
          type: 'checkbox',
          text: 'Transpose rows and columns',
          conditions: [
            [
              'type',
              'in',
              [
                'form_responses',
                'activities_table',
              ],
            ],
          ],
          default: state.forms_configuration.transpose || false,
        },
        ongoing: {
          type: 'checkbox',
          text: 'Include ongoing submissions',
          conditions: [
            [
              'type',
              'not_in',
              [
                ...state.ongoing_submissions_types,
                'activity_history',
                'features_map',
                null,
              ],
            ],
          ],
          default: state.forms_configuration.ongoing || false,
        },
        timeseries_active: {
          type: 'checkbox',
          text: 'Timeseries',
          conditions: [
            function (form$) {
              if (state.datetime_widget_types.includes(form$.el$('type')?.value))
                return false;

              else
                if (
                  ['datetime', 'date'].includes(form$.el$('field')?.value?.type)
                  && form$.el$('type')?.value === 'vertical_graph'
                )
                  return true;

              return false;
            },
          ],
          default: state.forms_configuration.timeseries_active || false,
        },
        timerange_type: {
          type: 'select',
          items: this.timerange_type_options,
          search: true,
          native: false,
          label: 'Timerange',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            function (form$) {
              if (state.datetime_widget_types.includes(form$.el$('type')?.value))
                return true;
              if (!form$.el$('timeseries_active')?.value)
                return false;

              else
                if (
                  ['datetime', 'date'].includes(form$.el$('field')?.value?.type)
                  && form$.el$('type')?.value === 'vertical_graph'
                )
                  return true;

              return false;
            },
          ],
          default: state.forms_configuration.timerange_type || this.timerange_type_options?.[0]?.value || null,
        },
        timerange_range: {
          type: 'date-time',
          label: 'Range',
          options: {
            range: true,
            format: 'dd MMM yyyy',
          },
          autocomplete: 'off',
          conditions: [
            function (form$) {
              if (form$.el$('timerange_type')?.value !== 'custom')
                return false;
              if (state.datetime_widget_types.includes(form$.el$('type')?.value))
                return true;
              if (!form$.el$('timeseries_active')?.value)
                return false;

              else
                if (
                  ['datetime', 'date'].includes(form$.el$('field')?.value?.type)
                  && form$.el$('type')?.value === 'vertical_graph'
                )
                  return true;

              return false;
            },
          ],
          default: state.forms_configuration.timerange_range || [],
        },
        timerange_interval: {
          type: 'select',
          items: this.timerange_interval_options,
          search: true,
          native: false,
          label: 'Interval',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            function (form$) {
              if (form$.el$('type')?.value === 'number_chart')
                return false;
              if (state.datetime_widget_types.includes(form$.el$('type')?.value))
                return true;
              if (!form$.el$('timeseries_active')?.value)
                return false;

              else
                if (
                  ['datetime', 'date'].includes(form$.el$('field')?.value?.type)
                  && form$.el$('type')?.value === 'vertical_graph'
                )
                  return true;

              return false;
            },
          ],
          default: state.forms_configuration.timerange_interval || this.timerange_interval_options?.[0]?.value || null,
        },

        include_filters: {
          type: 'checkbox',
          text: 'Include filters',
          conditions: [
            [
              'type',
              'in',
              [
                'activity_history',
              ],
            ],
          ],
          default: state.forms_configuration.include_filters || false,
        },

        activity_history_filters: {
          type: 'select',
          items: this.activity_history_filter_options,
          search: true,
          native: false,
          label: 'Add filters',
          canClear: false,
          canDeselect: false,
          valueProp: 'uid',
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            function (form$) {
              return form$.el$('type')?.value === 'activity_history' && form$.el$('include_filters')?.value;
            },
          ],
          default: state.forms_configuration.activity_history_filters || 'all',
        },

        module: {
          type: 'hidden',
          default: 'forms',
        },

      };
    },
    asset_id() {
      const dashboard_store = useDashboardStore();

      return dashboard_store.widget_asset;
    },
    activity_history_filter_options(state) {
      const form_template_detail_store = useFormTemplateDetailStore();
      const form = form_template_detail_store.form_template_detail;
      return [
        { label: 'All', uid: 'all' },
        { label: `${form?.name}s`, uid: 'form' },
        { label: 'Approvals', uid: 'approvals' },
        { label: 'Template', uid: 'template' },
      ];
    },
  },
  actions: {
    update_forms_configuration(data) {
      this.forms_configuration = { ...data };
    },
    set_form_dashboard_uid(uid) {
      this.forms_configuration.form = uid;
      this.forms_configuration.template = uid;
      this.is_form_dashboard = true;
    },
    set_form_dashboard_template() {
      // if it is inside the form dashboard, just get the template details
      // and add them to the option list in a single element
      const form_template_detail_store = useFormTemplateDetailStore();
      const form = form_template_detail_store.form_template_detail;

      this.template_options = [{
        ...form,
        value: form.uid,
        label: form.name,
      }];
    },
    format_draft_template_label(form = {}) {
      return {
        ...form,
        label_template: `
            <span>
              ${form?.name || form?.label} <span class="!text-red-700 ml-2">(Draft)</span>
            </span>
          `,
        // for the single-label slot
        label: `
            <span>
              ${form?.name || form?.label} <span class="!text-red-700 ml-2">(Draft)</span>
            </span>
          `,
        value: form?.value,
        disabled: true,
      };
    },
    set_disable_fields_exception(value) {
      this.disable_fields_exception = [...value];
    },
    handle_draft_template(options = []) {
      // get templates that are published, or the draft template sorted by name
      const sorted_options = sortBy(options.filter(t => t.status === 'published' || t.uid === this.forms_configuration.template).map(t => ({
        ...t,
        label: t.name,
        value: t.uid,
      })), f => f.label);

      if (!this.forms_configuration.template) {
        // if there is no initial value, return all options
        return sorted_options;
      }
      else {
        const res = [...sorted_options];
        const template_index = res.findIndex(t => t.value === this.forms_configuration.template);
        // if the initial form status is not publised, get the `Draft` label
        if (res[template_index]?.status !== 'published') {
          res[template_index] = this.format_draft_template_label(res[template_index]);
          this.set_disable_fields_exception(['template']);
          this.set_draft_form_uid(res[template_index].value);
        }

        return res;
      }
    },
    async set_templates() {
      if (this.is_form_dashboard) {
        this.set_form_dashboard_template();
      }
      else {
        this.template_options = [];
        const { data } = await this.$services.forms.getAll({
          query: {
            asset_uid: this.asset_id || null,
            page_size: 100000000,
          },
          attribute: 'templates',
        });
        this.template_options = this.handle_draft_template(Object.values(data?.templates || {}));
      }
    },
    async set_template_forms() {
      this.template_forms_options = [];
      const query = {
        search: this.search_query,
        status: 'published',
        is_child: true,
        asset_uid: this.asset_id,
        all_access: true,
        page_size: 3000,
        public: false,
        parent_form_uid: this.forms_configuration.template,
      };

      const { data } = await this.$services.forms.getAll({ query });
      const options = data.forms.map((form) => {
        return {
          label: form.name,
          value: form.uid,
        };
      });

      this.template_forms_options = await this.handle_draft_form(options, this.forms_configuration.template_form);
    },
    async handle_draft_form(options = [], form_uid = null, is_template = false) {
      const dashboard_store = useDashboardStore();

      // eslint-disable-next-line eqeqeq
      if (this.draft_form_uid && this.draft_form_uid != form_uid)
        return options;

      if (!dashboard_store.is_editing_widget || this.forms_configuration.type === 'forms_list' || this.forms_configuration.type === 'forms_list_with_tabs' || !form_uid)
        return options;

      const is_draft = options.find(f => f.value === form_uid) === undefined;

      if (!is_draft)
        return options;

      let form_data = null;
      try {
        if (is_template) {
          const { data } = await this.$services.forms.getAll({
            attribute: `templates/${form_uid}`,
            toast: false,
          });
          form_data = data.template;
        }
        else {
          const { data } = await this.$services.forms.get({
            id: form_uid,
            toast: false,
          });
          form_data = data.form;
        }
      }
      catch (err) {
        return options;
      }

      this.set_draft_form_uid(form_uid);
      this.set_disable_fields_exception(['form', 'template_form']);

      if (form_data)
        return [
          this.format_draft_template_label({
            ...form_data,
            value: form_data.uid,
          }),
          ...options,
        ];

      return [
        ...options,
      ];
    },
    async set_forms() {
      this.forms_options = [];

      const query = {
        status: 'published',
        page_size: 100000000,
        is_child: false,
        // ...(dashboard_store.scope !== 'organization'
        //   ? {
        asset_uid: this.asset_id,
        //   }
        // : {}),
      };

      const { data } = await this.$services.forms.getAll({ attribute: 'templates', query });
      const options = data.templates.map((form) => {
        return {
          label: form.name,
          value: form.uid,
        };
      });

      this.forms_options = await this.handle_draft_form(options, this.forms_configuration.form, true);
    },
    async set_form_fields() {
      if (['single_form_view', 'features_map'].includes(this.forms_widget_type)) {
        await this.set_templates();
        await this.set_template_forms();
        if (this.forms_widget_type === 'features_map')
          await this.set_containers();
      }

      else {
        const form_id = this.forms_configuration?.form || null;
        if (!form_id)
          return;

        if (this.forms_widget_type === 'form_responses')
          await this.set_form_responses_fields(form_id);

        else if (this.forms_widget_type !== 'forms_list' && this.forms_widget_type !== 'forms_list_with_tabs')
          await this.set_axis_values(form_id);

        else if (!['single_form_view', 'forms_list', 'forms_list_with_tabs'].includes(this.forms_widget_type))
          this.set_filter_fields(form_id);
      }
    },
    set_draft_form_uid(uid) {
      this.draft_form_uid = uid;
    },
    async set_filter_fields(form_id) {
      const { data } = await this.$services.forms.get({
        id: `templates/${form_id}`,
      });

      this.is_workflow_template = !!data?.template?.workflow;

      let fields = data?.template?.sections.flatMap((section) => {
        return section.fields.map(field => ({
          ...field,
          section_name: section.name,
          section_id: section.uid,
          ...(data?.template?.workflow
            ? {
                form_block_name: data.template.steps[section.step_index].name,
                form_block_id: `block_${section.step_index}`,
              }
            : {}
          ),
        }));
      });

      fields = fields.filter(field => (
        field.type !== 'group'
        && !field.hidden_in_settings
        && field.name
        && field.name !== ''
        && field.properties?.type !== 'info'
      ));

      this.filter_fields = fields.map(field => ({
        label: field.name,
        config: field.config,
        type: field.type,
        value: field.uid,
        section_name: field.section_name,
        section_id: field.section_id,
        ...(data?.template?.workflow
          ? {
              form_block_name: field.form_block_name,
              form_block_id: field.form_block_id,
            }
          : {}),
      }));
    },
    async set_axis_values(form_id) {
      this.options_loading = true;
      const { data } = await this.$services.forms.get({
        id: form_id,
        attribute: 'reports-fields',
      });

      this.xfield_options = data.x.map((item) => {
        return {
          ...item,
          value: item.key,
        };
      });

      this.yvalue_options = data.y.map((item) => {
        return {
          ...item,
          value: item.key,
        };
      });

      this.breakdown_options = [
        { value: 'none', label: 'None', key: 'none' },
        ...data.group.map((item) => {
          return {
            ...item,
            value: item.key,
          };
        }),
      ];

      this.options_loading = false;

      return true;
    },
    async set_form_responses_fields(form_id) {
      this.options_loading = true;

      const { data } = await this.$services.forms.get({
        id: form_id,
        attribute: 'reports-fields',
        query: {
          submission_report: true,
        },
      });

      const fields = data.fields.map((item) => {
        return {
          ...item,
        };
      });
      this.form_response_options = keyBy(fields, 'key');

      this.options_loading = false;

      return true;
    },
    getMinW(widget_type) {
      if (widget_type === 'forms_list' || widget_type === 'forms_list_with_tabs')
        return 8;
      if (widget_type === 'single_form_view')
        return 12;
      return 4;
    },
    chart_type(widget_type) {
      if (widget_type === 'status_breakdown')
        return 'donut';

      else if (widget_type === 'assignee_breakdown')
        return 'vertical_graph';

      else if (widget_type === 'asset_breakdown')
        return 'horizontal_bar';

      else
        return widget_type;
    },
    update_forms_list_relative_date_filters(filters) {
      const filters_copy = filters.filters;
      if (filters?.report_filters?.length)
        filters.report_filters.forEach((f) => {
          if (isDateIntervalOperator(f.operator)) {
            const [first_day_date, last_day_date] = getDateInterval('week');

            filters_copy[`${f.filter_type}_start`] = first_day_date.toISOString();
            filters_copy[`${f.filter_type}_end`] = last_day_date.toISOString();
          }
        });
      return filters_copy;
    },
    update_relative_date_filters(filters) {
      const rules = filters.rules.reduce((acc, f) => {
        if (!f.date_operator)
          acc.push(f);
        else
          acc.push({
            ...f,
            value: getDateInterval(f.date_operator),
          });

        return acc;
      }, []);

      return {
        logic: filters.logic,
        rules,
      };
    },
    parse_forms_form_to_server_format(_widget_data) {
      const widget_data = cloneDeep(_widget_data);
      if (!Object.keys(widget_data || {}).length)
        return null;

      const authStore = useAuthStore();

      const has_axis_widget_data = this.axis_widget_types.includes(widget_data.type);
      const has_group = (
        this.axis_widget_types.includes(widget_data.type)
        && !['number_chart', 'donut'].includes(widget_data.type)
        && widget_data.breakdown?.value !== 'none'
      );

      const axis_payload = {};

      if (has_axis_widget_data && widget_data?.field?.value) {
        const x_value = widget_data.field;
        const y_value = widget_data.value;

        if (widget_data.cumulative)
          x_value.cumulative = 'sum';
        else if (x_value?.cumulative)
          delete x_value.cumulative;

        const x_timeseries = widget_data.type === 'vertical_graph' ? widget_data.timeseries_active : undefined;
        const x_display_previous = widget_data.type === 'number_chart' ? widget_data.display_previous_value : false;

        if (this.has_timeseries && x_value) {
          x_value.timerange = {};
          x_value.timerange = {
            type: widget_data.timerange_type,
            range: widget_data.timerange_range,
            interval: widget_data.timerange_interval,
          };

          x_value.timeseries = x_timeseries;
          x_value.displayPreviousValue = x_display_previous;
        }

        let group_value;
        if (has_group && ![null, 'none'].includes(widget_data.breakdown?.value))
          group_value = widget_data.breakdown;

        axis_payload.x = x_value;
        axis_payload.y = y_value;
        axis_payload.group = group_value || undefined;
      }

      const payload = {
        ongoing: widget_data.ongoing || false,

        properties: {
          source: {
            type: 'forms',
            service: 'forms',
          },
          config: widget_data.chart_config || {},
        },
        filters: {

          // form_id: widget_data.form,
          organization: authStore.current_organization?.uid,
          asset_uid: widget_data.asset_id,
        },
        ...axis_payload,
        chart: this.chart_type(widget_data.type),
        scope: widget_data.scope ? 'user' : 'organization',
        minW: this.getMinW(),

      };

      if (widget_data.type === 'single_form_view') {
        payload.filters.form_id = widget_data.template_form;
      }
      else if (widget_data.type === 'features_map') {
        payload.filters.form_id = widget_data.template;
        payload.filters.container_uid = widget_data.container;
      }
      else { payload.filters.form_id = widget_data.form; }

      // update date filters at runtime, ex 'this_month'
      if (widget_data.type === 'forms_list_with_tabs')
        payload.tabs = widget_data.tabs;
      if ((widget_data.type === 'forms_list') && widget_data.filters?.report_filters?.length) {
        payload.properties.report_filters = widget_data.filters.report_filters;
        payload.filters = {
          ...payload.filters,
          ...this.update_forms_list_relative_date_filters(widget_data.filters),
        };
      }

      else if (widget_data.filters?.rules?.length && widget_data.type !== 'single_form_view' && widget_data.type !== 'activity_history') {
        payload.filters.advanced_filter = this.update_relative_date_filters(widget_data.filters);
      }
      const has_transpose_field = ['form_responses', 'activities_table'].includes(widget_data.type);

      if (has_transpose_field)
        payload.properties.transpose = widget_data.transpose;

      if (this.axis_widget_types.includes(widget_data.type))
        payload.agg = {
          key: widget_data.summary,
          label: widget_data.summary,
        };
      else
        payload.type = widget_data.type;

      if (widget_data.type === 'form_responses' && widget_data.columns?.length)
        payload.fields = widget_data.columns;

      if (widget_data.type === 'number_chart') {
        payload.properties.prettify_values = widget_data.prettify_values;
        payload.properties.inverse_trend = widget_data.inverse_trend;
      }

      if (widget_data.type === 'activity_history') {
        payload.include_filters = widget_data.include_filters;
        payload.activity_history_filters = widget_data.activity_history_filters;
      }

      if (['form_responses', 'activities_table', 'forms_list', 'forms_list_with_tabs'].includes(widget_data.type))
        payload.properties.columns_widths = widget_data.columns_widths || {};

      if (widget_data.type === 'single_form_view') {
        payload.properties.single_form_visibility = widget_data.single_form_visibility || null;
        payload.properties.template = widget_data.template;
      }

      if (this.ongoing_submissions_types.includes(widget_data.type))
        payload.ongoing = widget_data.ongoing;

      return payload;
    },
    set_single_form_data(data) {
      this.single_form_data = [...data];
    },
    get_initial_filters(widget_data, widget_type) {
      if (widget_type === 'single_form_view')
        return {};

      if (widget_type === 'forms_list') {
        const filters = {};
        filters.report_filters = widget_data.properties?.report_filters || [];

        const formatted_filters = omit(
          widget_data.filters,
          ['organization', 'asset_uid', 'form_id'],
        );

        filters.filters = formatted_filters;

        return filters;
      }
      else {
        return widget_data.filters?.advanced_filter || {};
      }
    },
    set_chart_initial_data(widget_data) {
      return {
        field: widget_data.x || null,
        value: widget_data.y || null,
        summary: widget_data.agg?.key || null,
        breakdown: widget_data.group || { value: 'none', label: 'None' },
        timerange_type: widget_data.x?.timerange?.type || null,
        timerange_range: widget_data.x?.timerange?.range || null,
        timerange_interval: widget_data.x?.timerange?.interval || null,
        cumulative: widget_data?.x?.cumulative === 'sum' || false,
        chart_config: widget_data.properties?.config || null,
        display_previous_value: widget_data?.x?.displayPreviousValue || false,
        timeseries_active: widget_data?.x?.timeseries || false,
      };
    },
    set_number_chart_initial_data(widget_data) {
      return {
        inverse_trend: widget_data?.properties?.inverse_trend || false,
        prettify_values: widget_data?.properties?.prettify_values || false,
      };
    },
    set_form_responses_initial_data(widget_data) {
      return {
        columns: widget_data?.fields || [],
      };
    },
    set_single_form_initial_data(widget_data) {
      return {
        template: widget_data?.properties?.template || null,
        single_form_visibility: widget_data?.properties?.single_form_visibility || null,
        template_form: widget_data.filters?.form_id || null,
      };
    },
    set_initial_asset(asset_id, type) {
      if (type === 'single_form_view')
        return asset_id || null;

      return asset_id || [];
    },
    unparse_forms_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const type = widget_data.type || widget_data.chart || null;

      return {
        type,
        ...(type === 'features_map' ? { template: widget_data.filters?.form_id || null } : { form: widget_data.filters?.form_id || null }),
        name: widget_data.properties?.name || 'Untitled',
        transpose: widget_data.properties?.transpose || false,
        scope: widget_data.scope === 'user',
        ongoing: widget_data.ongoing || false,
        columns_widths: widget_data.properties?.columns_widths || {},
        asset_id: this.set_initial_asset(widget_data.filters?.asset_uid, type),
        module: 'forms',
        ...this.set_chart_initial_data(widget_data),
        ...(type === 'number_chart' && this.set_number_chart_initial_data(widget_data)),
        ...(type === 'form_responses' && this.set_form_responses_initial_data(widget_data)),
        ...(type === 'single_form_view' && this.set_single_form_initial_data(widget_data)),
        filters: this.get_initial_filters(widget_data, type),
        tabs: widget_data.tabs || [],
        include_filters: widget_data.include_filters,
        activity_history_filters: widget_data.include_filters ? widget_data.activity_history_filters : 'all',
        container: widget_data.filters?.container_uid || null,
      };
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'forms'
      ) {
        this.data_set = true;
        this.forms_configuration = {
          ...this.forms_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.forms_configuration = { ...widget_configuration };

      this.data_set = true;
    },
    set_forms_v2_filters(filters, fam_overview_display_filters_ref) {
      if (filters.length)
        this.forms_v2_filters = [{ logic: { type: 'AND' }, rules: filters }];
      else
        this.forms_v2_filters = [];
      this.fam_overview_display_filters_ref = fam_overview_display_filters_ref;
    },
    async set_containers() {
      const { data } = await this.$services.terra_view_service.getAll({
        query: {
          asset: this.asset_id,
        },
      });

      this.container_options = sortBy(data?.map((c) => {
        return {
          value: c.uid,
          label: c.name,
        };
      }) || [], ['label']);
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardFormsStore, import.meta.hot));
