import { acceptHMRUpdate, defineStore } from 'pinia';
import { capitalize } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';

export const useDashboardAssetsStore = defineStore('dashboard_assets', {
  state: () => ({
    assets_configuration: {},
    axis_widget_types: [
      'vertical_graph',
      'horizontal_bar',
      'donut',
    ],
    group_widget_types: [
      'vertical_graph',
      'horizontal_bar',
    ],
    timerange_type_options: [
      ['all', 'All time'],
      ['year', 'This year'],
      ['month', 'This month'],
      ['week', 'This week'],
      ['day', 'Today'],
      ['custom', 'Custom'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    supported_field_types_map: {
      metric: ['number', 'money', 'percentage', 'planned_actual', 'date_range'],
      progress_card: ['planned_actual'],
      vertical_graph: ['asset_name', 'text', 'dropdown', 'members', 'date', 'datetime'],
      horizontal_bar: ['asset_name', 'text', 'dropdown', 'members', 'date', 'datetime'],
      donut: ['asset_name', 'text', 'dropdown', 'members', 'date', 'datetime'],
      asset_pivot_table: [],
    },
    value_supported_field_types: ['text', 'dropdown', 'number', 'percentage', 'planned_actual', 'date_range'],
    group_supported_field_types: ['text', 'dropdown', 'members'],
    default_aggregations: [
      { key: 'sum', label: 'Sum' },
      { key: 'avg', label: 'Average' },
      { key: 'min', label: 'Min' },
      { key: 'max', label: 'Max' },
      { key: 'count', label: 'Count' },
    ],
    aggregation_options_map: {
      text: [{ key: 'count', label: 'Count' }],
      dropdown: [{ key: 'count', label: 'Count' }],
      number: [
        { key: 'sum', label: 'Sum' },
        { key: 'avg', label: 'Average' },
        { key: 'min', label: 'Min' },
        { key: 'max', label: 'Max' },
        { key: 'count', label: 'Count' },
      ],
      percentage: [
        { key: 'sum', label: 'Sum' },
        { key: 'avg', label: 'Average' },
        { key: 'min', label: 'Min' },
        { key: 'max', label: 'Max' },
        { key: 'count', label: 'Count' },
      ],
      planned_actual: [
        { key: 'sum', label: 'Sum' },
        { key: 'avg', label: 'Average' },
        { key: 'min', label: 'Min' },
        { key: 'max', label: 'Max' },
        { key: 'count', label: 'Count' },
      ],
      date_range: [
        { key: 'sum', label: 'Sum' },
        { key: 'avg', label: 'Average' },
        { key: 'min', label: 'Min' },
        { key: 'max', label: 'Max' },
        { key: 'count', label: 'Count' },
      ],
    },

    data_set: false,
  }),
  getters: {
    type_options() {
      const auth_store = useAuthStore();
      return [
        ['metric', 'Metric Chart'],
        ['progress_card', 'Progress card'],
        ['vertical_graph', 'Vertical Bar Chart'],
        ['horizontal_bar', 'Horizontal Bar Chart'],
        ['donut', 'Pie Chart'],
        ...(auth_store.check_split('pivot_dashboard_widget') ? [['asset_pivot_table', 'Pivot table']] : []),
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          description: item[2],
        };
      });
    },
    all_asset_fields: () => {
      const common_store = useCommonStore();
      return common_store.assets_custom_fields.map(field => ({
        key: field.uid,
        uid: field.uid,
        type: field.type,
        label: field.name,
      }));
    },
    asset_field_options: (state) => {
      const supported_field_types = state.supported_field_types_map[state.asset_widget_type];
      return [
        ...(supported_field_types.includes('asset_name')
          ? [{
              key: 'asset_name',
              uid: 'asset_name',
              type: 'text',
              label: 'Asset name',
            }]
          : []),
        ...state.all_asset_fields.filter(field => supported_field_types.includes(field.type)),
      ];
    },
    asset_value_options: (state) => {
      return state.all_asset_fields.filter(field => state.value_supported_field_types.includes(field.type));
    },
    asset_group_options: (state) => {
      const none_group = {
        key: 'null',
        uid: null,
        type: 'none',
        label: 'None',
      };
      return [none_group, ...state.all_asset_fields.filter(field => state.group_supported_field_types.includes(field.type))];
    },
    asset_aggregation_options: (state) => {
      if (state.axis_widget_types.includes(state.asset_widget_type))
        return state.aggregation_options_map[state.asset_value.type] || [];
      return state.default_aggregations;
    },
    asset_widget_type: (state) => {
      return state.assets_configuration.type || state.type_options[0].value;
    },
    asset_value: (state) => {
      return state.assets_configuration.value || state.asset_value_options[0];
    },
    timerange_interval_options: (state) => {
      let options = [];
      if (['all', 'custom'].includes(state.assets_configuration.timerange_type))
        options = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];
      else if (state.assets_configuration.timerange_type === 'year')
        options = ['monthly', 'quarterly', 'yearly'];
      else if (state.assets_configuration.timerange_type === 'month')
        options = ['daily', 'weekly'];
      else
        options = ['daily'];

      return options.map(item => ({
        value: item,
        label: capitalize(item),
      }));
    },
    show_timeseries_active_input: (state) => {
      return state.axis_widget_types.includes(state.asset_widget_type) && ['date', 'datetime'].includes(state.assets_configuration.field?.type);
    },
    assets_schema: (state) => {
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.assets_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        field: {
          type: 'select',
          items: state.asset_field_options,
          search: true,
          native: false,
          valueProp: 'uid',
          labelProp: 'label',
          label: 'Field',
          object: true,
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: 'required',
          default: state.assets_configuration.field || state.asset_field_options[0] || null,
          conditions: [
            ['type', '!=', 'asset_pivot_table'],
          ],
        },
        agg: {
          type: 'select',
          items: state.asset_aggregation_options,
          search: true,
          native: false,
          label: 'Summary',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          object: true,
          valueProp: 'key',
          labelProp: 'label',
          rules: 'required',
          default: state.assets_configuration.agg || state.asset_aggregation_options[0] || null,
          conditions: [
            ['type', '!=', 'asset_pivot_table'],
          ],
        },
        ...(state.axis_widget_types.includes(state.asset_widget_type) && {
          value: {
            type: 'select',
            items: state.asset_value_options,
            search: true,
            native: false,
            label: 'Value',
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            valueProp: 'uid',
            labelProp: 'label',
            rules: 'required',
            default: state.assets_configuration.value || state.asset_value_options[0],
          },
        }),
        ...(state.group_widget_types.includes(state.asset_widget_type) && {
          group: {
            type: 'select',
            items: state.asset_group_options,
            search: true,
            native: false,
            label: 'Group',
            canClear: false,
            canDeselect: false,
            object: true,
            inputType: 'search',
            autocomplete: 'off',
            valueProp: 'key',
            labelProp: 'label',
            default: state.assets_configuration.group || state.asset_group_options[0],
          },
        }),
        ...(state.show_timeseries_active_input && {
          timeseries_active: {
            type: 'checkbox',
            text: 'Timeseries',
            default: state.assets_configuration.timeseries_active || false,
          },
        }),
        ...(state.assets_configuration.timeseries_active && {
          timerange_type: {
            type: 'select',
            items: state.timerange_type_options,
            search: true,
            native: false,
            label: 'Timerange',
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.assets_configuration.timerange_type || state.timerange_type_options?.[0]?.value || null,
          },
        }),
        ...(state.assets_configuration.timerange_type === 'custom' && {
          timerange_range: {
            type: 'date-time',
            label: 'Range',
            options: {
              range: true,
              format: 'dd MMM yyyy',
            },
            autocomplete: 'off',
            default: state.assets_configuration.timerange_range || [],
          },
        }),
        ...(state.assets_configuration.timeseries_active && {
          timerange_interval: {
            type: 'select',
            items: state.timerange_interval_options,
            search: true,
            native: false,
            label: 'Interval',
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            default: state.assets_configuration.timerange_interval || state.timerange_interval_options?.[0]?.value || null,
          },
        }),
        module: {
          type: 'hidden',
          default: 'assets',
        },
      };
    },
  },
  actions: {
    update_assets_configuration(data) {
      this.assets_configuration = { ...data };
    },

    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
      ) {
        this.data_set = true;
        this.assets_configuration = {
          ...this.assets_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      const data = widget_configuration;
      this.assets_configuration = { ...data };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardAssetsStore, import.meta.hot));
