<!-- eslint-disable @typescript-eslint/no-invalid-this -->
<script>
import {
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useVueTable,
} from '@tanstack/vue-table';
import { debounce } from 'lodash-es';
import HeaderColumn from './header-column.vue';
import TableRow from './table-row.vue';
import { useViewConfiguration } from '~/common/composables/useViewConfiguration.js';

export default {
  components: {
    HeaderColumn,
    TableRow,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    default_columns: {
      type: Array,
      default: () => [],
    },
    additional_row_classes: {
      type: String,
      required: false,
      default: '',
    },
    striped: {
      type: Boolean,
      default: false,
    },
    container_class: {
      type: String,
      required: false,
      default: '',
    },
    additional_table_classes: {
      type: String,
      required: false,
      default: 'align-middle',
    },
    parent_id_for_infinite_scrolling: {
      type: String,
      required: false,
      default: '',
    },
    disable_resize: {
      type: Boolean,
      default: false,
    },
    is_gapless: {
      type: Boolean,
      default: false,
    },
    non_sortable_columns: {
      type: Array,
      default: () => ['select'],
    },
    // eslint-disable-next-line vue/prop-name-casing
    getChildren: {
      type: Function,
      default: null,
    },
    pagination_config: {
      type: Object,
      default: null,
    },
    sub_rows_key: {
      type: String,
      default: 'subRows',
    },
    manual_sorting: {
      type: Boolean,
      default: false,
    },
    manual_pagination: {
      type: Boolean,
      default: false,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    enable_infinite_scroll: {
      type: Boolean,
      default: false,
    },
    is_loading_on_scroll: {
      type: Boolean,
      default: false,
    },
    enable_dnd: {
      type: Boolean,
      default: false,
    },
    column_order: {
      type: Array,
      default: () => [],
    },
    group_by: {
      type: String,
      default: '',
    },
    group_by_function: {
      type: Function,
      default: null,
    },
    grouping_row_classes: {
      type: String,
      required: false,
      default: 'border border-gray-300 rounded-lg bg-gray-100 py-3',
    },
    expanded_group_keys: {
      type: Array,
      default: () => [],
    },
    sticky_group_label: {
      type: Boolean,
      default: false,
    },
    show_menu_header: {
      type: Boolean,
      default: true,
    },
    table_header_sticky: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    formatTable: {
      type: Function,
      default: null,
    },
    show_footer: {
      type: Boolean,
      default: false,
    },
    footer_type: {
      type: String,
      default: '',
    },
    footer_function: {
      type: Function,
      default: null,
    },
    footer_offset: {
      type: Number,
      default: 0,
    },
    sub_row_selection: {
      type: Boolean,
      default: false,
    },
    is_vueform_element_enabled: {},
    arrows: {
      type: String,
      required: false,
      default: 'left',
    },
    freeze_table: {
      type: String,
      default: '-1',
    },
    header_visible: {
      type: Boolean,
      default: true,
    },
    is_skeleton_loading_enabled: {
      type: Boolean,
      default: false,
    },
    show_column_borders: {
      type: Boolean,
      default: false,
    },
    stick_header_offset: {
      type: String,
    },
    freeze_column_id: {
      type: String,
      default: '',
    },
    header_styles: {
      type: Object,
      default: () => {},
    },
    cell_height: {
      type: String,
      default: '',
    },
    header_grid_lines: {
      type: Object,
      default: () => ({
        horizontal: true,
        vertical: false,
      }),
    },
    data_grid_lines: {
      type: Object,
      default: () => ({
        horizontal: true,
        vertical: false,
      }),
    },
    scroll_to_top_on_pagination: {
      type: Boolean,
      default: false,
    },
    custom_view_configuration: {
      type: Object,
    },
    header_context_menu_config: {
      type: Object,
      default: () => ({
        is_menu_fixed: false,
        column_settings: false,
        additional_items: [],
      }),
    },
    select_item_on_row_click: {
      type: Boolean,
      default: false,
    },
  },
  // All the table emits are listed below
  emits: [
    'sort',
    'selectRow',
    'subRows',
    'pagination',
    'loadMore',
    'rowClicked',
    'cellClick',
    'colMoving',
    'colDragStart',
    'colDragEnd',
    'columnPinned',
    'columnUnpinned',
    'rowMouseOver',
    'rowMouseLeave',
    'rowDblClick',
    'tableInstance',
    'tableInstanceCreated',
    'columnResized',
    'visibilityToggled',
  ],
  setup(props) {
    const { setView, updateView, visible_columns_map, active_view, is_custom_view_enabled } = useViewConfiguration(props.custom_view_configuration, props.visible_columns);
    const Draggable = shallowRef(null);
    return {
      setView, updateView, visible_columns_map, active_view, is_custom_view_enabled, Draggable
    };
  },
  data() {
    return {
      table: null,
      isResizing: false,
      table_sort: [],
      selected_rows: {},
      expanded_state: {},
      dragging_column: false,
      pagination_state: {
        pageIndex: 0,
        pageSize: 25,
      },
      table_col_order: [],
      show_group: {},
      frozen_col_id: '',
      scroll_x: 0,
      table_width: 0,
      pagination_width: 0,
      group_header_top: 0,
      curr_page_row_ids: [],
      is_context_menu_opened: false,
      is_views_loading: false,
    };
  },
  computed: {
    get_pagination_state() {
      return { ...this.pagination_state, pageIndex: this.pagination_state.pageIndex + 1 };
    },
    getPaginationLimit() {
      const computedRecords = (this.table.getState().pagination.pageIndex + 1) * this.table.getState().pagination.pageSize;
      const availableRecords = this.pagination_config.totalRows || this.data?.length;
      return computedRecords > availableRecords ? availableRecords : computedRecords;
    },
    skeleton_visible_columns() {
      const visible_columns = this.get_visible_columns;
      const visible_columns_length = visible_columns.length;
      if (visible_columns[0] === 'select' && visible_columns[visible_columns_length - 1] === 'context_menu')
        return visible_columns_length - 2;
      if ((visible_columns[0] === 'select' && visible_columns[visible_columns_length - 1] !== 'context_menu') || (visible_columns[0] !== 'select' && visible_columns[visible_columns_length - 1] === 'context_menu'))
        return visible_columns_length - 1;
      return visible_columns_length;
    },
    is_pagination_enabled() {
      return this.default_pagination_config && (this.default_pagination_config.totalRows > this.default_pagination_config.noOfRows[0]) && !this.$props.enable_infinite_scroll;
    },
    default_pagination_config() {
      const config = {
        noOfRows: [25, 50, 100],
        rowSize: 25,
        totalRows: null,
      };
      if (this.pagination_config)
        return {
          ...config,
          ...this.pagination_config,
        };

      return null;
    },
    getPages() {
      const currPage = this.pagination_state.pageIndex + 1;
      const pageCount = Math.ceil(this.pagination_config.totalRows / this.pagination_state.pageSize);
      const pageRange = 3;
      const marginPages = (currPage < 3 || (pageCount - currPage) < 3) ? 3 : 1;
      const items = {};
      if (pageCount <= pageRange) {
        for (let index = 0; index < pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === (currPage - 1),
          };
          items[index] = page;
        }
        return items;
      }
      const halfPageRange = Math.floor(pageRange / 2);
      const setPageItem = (index) => {
        const page = {
          index,
          content: index + 1,
          selected: index === (currPage - 1),
        };
        items[index] = page;
      };
      const setBreakView = (index) => {
        const breakView = {
          disabled: true,
          breakView: true,
        };
        items[index] = breakView;
      };

      for (let i = 0; i < marginPages; i++)
        setPageItem(i);

      let selectedRangeLow = 0;
      if (currPage - halfPageRange > 0)
        selectedRangeLow = currPage - 1 - halfPageRange;

      let selectedRangeHigh = selectedRangeLow + pageRange - 1;
      if (selectedRangeHigh >= pageCount) {
        selectedRangeHigh = pageCount - 1;
        selectedRangeLow = selectedRangeHigh - pageRange + 1;
      }
      for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= pageCount - 1; i++)
        setPageItem(i);

      if (selectedRangeLow > marginPages)
        setBreakView(selectedRangeLow - 1);

      if (selectedRangeHigh + 1 < pageCount - marginPages)
        setBreakView(selectedRangeHigh + 1);

      for (let i = pageCount - 1; i >= pageCount - marginPages; i--)
        setPageItem(i);

      return items;
    },
    get_visible_columns() {
      return this.table_col_order.filter(col_id => this.table.getColumn(col_id).getIsVisible());
    },
    getRowsData() {
      let all_rows = this.table.getRowModel().rows;
      if ((!this.manual_pagination || this.enable_infinite_scroll) && this.pagination_config)
        all_rows = all_rows.slice(this.enable_infinite_scroll ? 0 : this.pagination_state.pageIndex * this.pagination_state.pageSize, (this.pagination_state.pageIndex + 1) * this.pagination_state.pageSize);

      const rows_with_sub_rows = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.curr_page_row_ids = [];
      all_rows.forEach((row) => {
        const res = [];
        this.recursiveSubRows(row, res);
        rows_with_sub_rows.push(...res);
        this.curr_page_row_ids.push(row.id);
        if (this.sub_row_selection && row.subRows)
          this.curr_page_row_ids.push(...row.subRows.map(sub_row => sub_row.id));
      });
      // eslint-disable-next-line vue/no-async-in-computed-properties
      setTimeout(() => this.updateWidth(), 500);
      if (!this.group_by && !this.group_by_function) {
        return rows_with_sub_rows;
      }
      else if (this.group_by) {
        return rows_with_sub_rows.reduce((group, row) => {
          const { original } = row;
          if (Object.keys(this.show_group || {}).length === 0)
            this.show_group[original[this.group_by]] = true;
          else
            this.show_group[original[this.group_by]] = this.show_group[original[this.group_by]] ?? false;
          group[original[this.group_by]] = group[original[this.group_by]] ?? {
            show: false,
            rows: [],
          };
          group[original[this.group_by]].rows.push(row);
          return group;
        }, {});
      }
      else {
        const row_data = this.group_by_function(rows_with_sub_rows);
        if (Object.keys(row_data).length)
          if (this.expanded_group_keys.length > 0)
            this.expanded_group_keys.forEach((group) => {
              this.show_group[group] = true;
            });
          else
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.show_group[Object.keys(row_data)[0]] = true;

        return row_data;
      }
    },
    getPageSizeOptions() {
      return this.default_pagination_config.noOfRows.map((row_count) => {
        return Object({
          label: row_count,
          uid: row_count,
          on_click: () => {
            this.updatePagination('pageSize', Number(row_count));
          },
        });
      });
    },
    getColumnById() {
      const column_by_id = {};
      const recursiveSubHeaders = (col) => {
        column_by_id[col.id] = col;
        (col.columns || []).forEach(sub_col => recursiveSubHeaders(sub_col));
      };
      this.columns.forEach(col => recursiveSubHeaders(col));
      return column_by_id;
    },
    getDataByColumn() {
      const data = {};
      let row_data = [];
      if (!this.group_by && !this.group_by_function)
        row_data = this.getRowsData;
      else
        Object.values(this.getRowsData).forEach(val => row_data.push(...val.rows));
      row_data.forEach((row) => {
        this.table_col_order.forEach((col_id) => {
          if (!['select', 'context_menu'].includes(col_id)) {
            const { original } = row;
            if (!Object.keys(data).includes(col_id))
              data[col_id] = [];
            data[col_id].push(original[col_id]);
          }
        });
      });
      return data;
    },
  },
  watch: {
    column_order: {
      handler(new_order) {
        if (new_order?.length)
          this.table_col_order = new_order;
      },
      immediate: true,
    },
    visible_columns_map: {
      handler(val) {
        this.handleVisibleColumnProp(val);
      },
      immediate: true,
    },
    freeze_table: {
      handler(val) {
        if (this.$parent.$options.name === 'TableWrapper')
          this.$parent.freeze_scroll = val !== '-1';
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.is_views_loading = true;
      await this.setView({}, this.columns, this.default_columns);
      this.is_views_loading = false;
    }
    catch (error) {
      logger.log(error);
      this.is_views_loading = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.table = useVueTable({
      get data() {
        return self.data;
      },
      columnResizeMode: 'onChange',
      paginateExpandedRows: false,

      manualPagination: true,
      manualExpanding: true,
      manualSorting: self.manual_sorting,
      enableSubRowSelection: this.sub_row_selection,
      columns: [...self.columns.map(column => Object({
        sortingFn: 'alphanumeric',
        ...column,
        sortDescFirst: false,
        ...(self.visible_columns_map?.[column?.id]?.size ? { size: self.visible_columns_map[column.id]?.size } : {}),
        ...(['select', 'context_menu'].includes(column.id) && {
          enableSorting: false,
          enableResizing: false,
          disable_dnd: true,
          maxSize: 80,
        }),
      })),
      ...((!self.columns.filter(col => col.id === 'context_menu').length && self.show_menu_header && [{
        accessorKey: 'context_menu',
        header: '',
        id: 'context_menu',
        size: '5',
        enableSorting: false,
        enableResizing: false,
      },
      ]) || [])],
      state: {
        get sorting() {
          return self.table_sort;
        },
        get rowSelection() {
          return self.selected_rows;
        },
        get pagination() {
          return self.pagination_state;
        },
        get expanded() {
          return self.expanded_state;
        },
      },
      getExpandedRowModel: getExpandedRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getSubRows: row => self.getChildren ? self.getChildren(row || {}) : row[self.sub_rows_key],
      getCoreRowModel: getCoreRowModel(),
      onRowSelectionChange: e => self.onRowSelectionChange(e),
      onSortingChange: (updaterOrValue) => {
        if (!this.dragging_column) {
          self.table_sort = updaterOrValue(self.table_sort);
          this.$emit('sort', { sort_by: self.table_sort[0], pagination_state: self.get_pagination_state });
        }
      },
    });
    this.$emit('tableInstanceCreated', this.table);

    this.table_col_order = this.table.getAllLeafColumns().map((col) => {
      col.pin('left');
      return col.id;
    });

    this.handleVisibleColumnProp(this.visible_columns_map);
    // Freezing column using prop
    if (this.freeze_column_id)
      this.pinColumn(this.freeze_column_id);
    if (this.pagination_config) {
      this.pagination_state.pageSize = this.pagination_config.pageSize;
      if (this.pagination_config.pageIndex)
        this.pagination_state.pageIndex = (this.pagination_config.pageIndex - 1) < 0 ? 0 : this.pagination_config.pageIndex - 1;
    }
    document.addEventListener('keyup', this.clearSelect);
  },
  async beforeMount() {
    if (this.enable_dnd)
      this.Draggable = (await import('vuedraggable')).default;
  },
  mounted() {
    const container = document.getElementById(this.parent_id_for_infinite_scrolling || 'table_container');
    if (container)
      container.addEventListener('scroll', this.handleScroll);
    else
      document.addEventListener('scroll', this.handleScroll);
    setTimeout(() => this.updateWidth(), 1000);
    window.addEventListener('resize', this.updateWidth);
    this.emitInstance();
  },
  unmounted() {
    const container = document.getElementById(this.parent_id_for_infinite_scrolling || 'table_container');
    if (container)
      container.removeEventListener('scroll', this.handleScroll);
    else
      document.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWidth);
  },
  methods: {
    handleScroll: debounce(function (event) {
      this.scroll_x = event.target.scrollLeft;
      if (this.enable_infinite_scroll)
        this.loadMore(event?.target);
    }, 200),
    handleVisibleColumnProp(val) {
      if (Object.keys(val || {}).length) {
        const ordered_keys_map = {};
        this.columns.forEach((column) => {
          try {
            if (column.id === 'select')
              ordered_keys_map[column.id] = -1;
            else if (column.id === 'context_menu')
              ordered_keys_map[column.id] = Number.MAX_SAFE_INTEGER;
            else
              ordered_keys_map[column.id] = val[column.id]?.order_index;

            const col = this.table?.getColumn?.(column.id);
            if (col?.getIsVisible() !== !!val[column.id] && !['select', 'context_menu'].includes(column.id))
              col?.toggleVisibility();
          }
          catch (error) {
            logger.error(error);
          }
        });
        if (this.is_custom_view_enabled) {
          this.table_col_order = Object.entries(ordered_keys_map)
            .sort(([keyA, valueA], [keyB, valueB]) => (valueA ?? 0) - (valueB ?? 0))
            .map(([key]) => key);

          this.table_col_order.forEach((col_id) => {
            this.table?.getColumn?.(col_id)?.pin?.('left');
          });
        }
      }
    },
    selectRow(e) {
      const rows = document.querySelectorAll('tr');
      rows.forEach(item => item.classList.remove('bg-gray-200'));
      e.closest('tr').classList.add('bg-gray-200');
    },
    updateExpandedState(row, e) {
      if (Object.keys(this.expanded_state).includes(row.id))
        this.$set(this.expanded_state, [row.id], !this.expanded_state[row.id]);
      else
        this.$set(this.expanded_state, [row.id], true);
      row.toggleExpanded(this.expanded_state[row.id]);
      this.$emit('subRows', { expanded: this.expanded_state[row.id], row: row.original });
    },
    draggingColumn(e) {
      if (!e)
        setTimeout(() => this.dragging_column = false, 500);
      else this.dragging_column = true;
      this.updateWidth();
    },
    updatePagination(key, value) {
      if (key === 'pageSize') {
        this.$set(this.pagination_state, 'pageIndex', 0);
        this.$set(this.pagination_state, 'pageSize', value);
      }
      else {
        let pageIndex = -1;
        if (key === 'next')
          pageIndex = this.pagination_state.pageIndex + 1;
        else if (key === 'prev')
          pageIndex = this.pagination_state.pageIndex - 1;
        else
          pageIndex = value;
        this.$set(this.pagination_state, 'pageIndex', pageIndex);
      }
      this.$emit('pagination', { sort_by: this.table_sort[0], pagination_state: this.get_pagination_state });
      if (this.scroll_to_top_on_pagination)
        window.scrollTo(0, 0);
      this.expanded_state = {};
    },
    onRowSelectionChange(sortFunc) {
      if (Object.keys(sortFunc() || {})?.length)
        this.curr_page_row_ids.forEach((row_id) => {
          this.selected_rows[row_id] = true;
        });
      else this.selected_rows = {};
      this.emitSelectedRows();
    },
    updateSelectedRows(row) {
      this.selected_rows[row.id] = !this.selected_rows[row.id];
      this.emitSelectedRows();
    },
    emitSelectedRows() {
      const table_model = this.table.getRowModel();
      // Creating an object for rows based on order in the table
      table_model.order = table_model.flatRows.reduce((acc, row, i) => {
        acc[row.id] = i + 1;
        return acc;
      }, {});
      // Converting the selected row id's to row data and sorting data based on table order
      this.$emit('selectRow',
        Object.keys(this.selected_rows)
          .reduce((acc, row_id) => {
            if (this.selected_rows[row_id])
              acc.push(table_model.rowsById[row_id]);
            return acc;
          }, [])
          .sort((a, b) => table_model.order[a.id] - table_model.order[b.id]),
      );
    },
    recursiveSubRows(row, res) {
      res.push(row);
      if (this.expanded_state[row.id])
        row.subRows.forEach(sub_row => this.recursiveSubRows(sub_row, res));
    },
    loadMore(e) {
      if (e && e.offsetHeight + e.scrollTop >= e.scrollHeight - 20 && !this.is_loading_on_scroll)
        if ((this.pagination_state.pageIndex + 1) * this.pagination_state.pageSize < this.pagination_config.totalRows) {
          logger.log('Loading page', this.pagination_state.pageIndex + 1);
          this.$set(this.pagination_state, 'pageIndex', this.pagination_state.pageIndex + 1);
          this.$emit('loadMore', { sort_by: this.table_sort[0], pagination_state: this.get_pagination_state, load_more: true });
        }
    },
    updateColumnOrder(e, header = false) {
      this.dragging_column = false;
      if (!header)
        this.table_col_order.splice(
          e.newIndex,
          0,
          this.table_col_order.splice(e.oldIndex, 1)[0],
        );
      this.table_col_order.forEach((col_id) => {
        this.table.getColumn(col_id).pin('left');
      });
      this.$emit('colDragEnd', e);
    },
    pinColumn(col_id) {
      if (this.frozen_col_id === col_id) {
        this.frozen_col_id = '';
        this.$emit('columnUnpinned', col_id);
      }
      else {
        const oldIndex = this.table_col_order.findIndex(colId => colId === col_id);
        if (oldIndex === -1)
          return;
        this.frozen_col_id = col_id;
        this.table_col_order.splice(
          this.table_col_order.includes('select') ? 1 : 0,
          0,
          this.table_col_order.splice(oldIndex, 1)[0],
        );
        this.$emit('columnPinned', col_id);
      }
      this.table_col_order.forEach((col_id) => {
        this.table.getColumn(col_id).pin('left');
      });
    },
    updateWidth() {
      const table_container = this.$refs.topmost_table_element?.parentElement;
      if (table_container && this.$parent?.$options?.name === 'TableWrapper')
        this.pagination_width = table_container?.clientWidth;
      else if (this.header_visible)
        this.pagination_width = this.$refs.table_head?.offsetWidth;
      else
        this.pagination_width = this.$refs.generic_table?.clientWidth;
      this.table_width = this.$refs.generic_table?.clientWidth;
      this.group_header_top = this.$refs.table_head?.clientHeight + (+this.stick_header_offset || 0);
    },
    clearSelect(evt) {
      if (evt.keyCode === 27) {
        this.selected_rows = {};
        this.$emit('selectRow', []);
      }
    },
    getInfoSlotAvailableRows(data) {
      return data.filter(val => val?.original?.uid ? this.$slots[`row_info_${val?.original?.uid}`] : false);
    },
    emitInstance() {
      this.$emit('tableInstance', {
        clearSort: () => {
          this.table_sort = [];
          this.$emit('pagination', { sort_by: this.table_sort[0], pagination_state: this.get_pagination_state });
          if (this.scroll_to_top_on_pagination)
            window.scrollTo(0, 0);
        },
        clearSelect: () => {
          this.selected_rows = {};
          this.$emit('selectRow', []);
        },
        currentSortOrder: () => this.table_sort[0],
        getVisibleColumns: () => this.table.getAllColumns().filter(column => column.getIsVisible()),
        resetPagination: (page = 0) => this.updatePagination('pageIndex', page),
        expandAll: () => {
          this.table.getExpandedRowModel().flatRows.forEach((row) => {
            if (row.getCanExpand() && !row.getIsExpanded())
              this.updateExpandedState(row);
          });
        },
        getAllRows: () => this.table.getExpandedRowModel().flatRows,
        collapseAll: () => this.expanded_state = {},
        toggleExpandedRow: (uid) => {
          this.table.getExpandedRowModel().flatRows.forEach((row) => {
            if (row.getCanExpand() && row.original.uid === uid)
              this.updateExpandedState(row);
          });
        },
        pagination_state: () => this.get_pagination_state,
      });
    },
  },
};
</script>

<template>
  <div id="hawk_table" ref="topmost_table_element" style="width: 100%" :class="[!is_gapless ? 'px-4 sm:px-6 lg:px-8' : '', container_class]">
    <div class="flex">
      <div :class="[additional_table_classes]" class="inline-block min-w-full rounded">
        <template v-if="!is_views_loading">
          <table
            ref="generic_table"
            :style="{
              width: `${table.getLeftTotalSize()}px`,
            }"
            class="min-w-full divide-y divide-gray-200 border-separate border-spacing-0"
            :class="{ 'no_pagination': !is_pagination_enabled, 'no-border-radius': (is_loading || is_loading_on_scroll || data?.length === 0) }"
          >
            <thead v-if="header_visible" ref="table_head" class="bg-gray-50" :class="[{ 'sticky top-0 z-10': table_header_sticky, 'z-[1000]': is_context_menu_opened }]" :style="stick_header_offset ? `top:${stick_header_offset}px;` : ''">
              <template v-if="enable_dnd">
                <template
                  v-for="(headerGroup, index) in table.getHeaderGroups()"
                  :key="headerGroup.id"
                >
                  <component :is="Draggable" v-if="Draggable"
                    v-model="headerGroup.headers" class="h-11" tag="tr" draggable=".is_draggable"
                    handle=".handle"
                    :itemKey="headerGroup.id"
                    @start="dragging_column = true; $emit('colDragStart', $event)"
                    @end="updateColumnOrder"
                    @move="$emit('colMoving', $event)"
                  >
                    <template #item="{ element: header }">
                      <HeaderColumn
                        :key="header.id"
                        :table="table"
                        :disable_resize="disable_resize"
                        :header="header"
                        :table_sort="table_sort"
                        :frozen_col_id="frozen_col_id"
                        :dragging_column="dragging_column"
                        :show_menu_header="show_menu_header"
                        :table_col_order="table_col_order"
                        :non_sortable_columns="non_sortable_columns"
                        :enable_dnd="enable_dnd"
                        :index="index"
                        :is_resizing="header.column.getIsResizing()"
                        :show_column_borders="show_column_borders"
                        :update_view="updateView"
                        @dropdownOpened="is_context_menu_opened = true"
                        @dropdownClosed="is_context_menu_opened = false"
                      >
                        <template #context-menu-header>
                          <slot name="context_menu-header" />
                        </template>
                        <template v-for="(val, col_id) in getColumnById" #[`${col_id}Header`]="col_data">
                          <slot :name="`${col_id}Header`" :data="col_data.data" />
                        </template>
                      </HeaderColumn>
                    </template>
                  </component>
                </template>
              </template>
              <template v-else>
                <tr
                  v-for="headerGroup in table.getHeaderGroups()"
                  :key="headerGroup.id"
                  class="h-11 bg-gray-50"
                >
                  <HeaderColumn
                    v-for="header in headerGroup.headers"
                    :key="header.id"
                    :table="table"
                    :header="header"
                    :table_sort="table_sort"
                    :disable_resize="disable_resize"
                    :frozen_col_id="frozen_col_id"
                    :dragging_column="dragging_column"
                    :show_menu_header="show_menu_header"
                    :table_col_order="table_col_order"
                    :non_sortable_columns="non_sortable_columns"
                    :is_resizing="header.column.getIsResizing()"
                    :update_view="updateView"
                    :show_column_borders="show_column_borders"
                    @dropdownOpened="is_context_menu_opened = true"
                    @dropdownClosed="is_context_menu_opened = false"
                  >
                    <template #context-menu-header>
                      <slot name="context-menu-header" />
                    </template>
                    <template v-for="(val, col_id) in getColumnById" #[`${col_id}Header`]="col_data">
                      <slot :name="`${col_id}Header`" :data="col_data.data" />
                    </template>
                  </HeaderColumn>
                </tr>
              </template>
            </thead>
            <tbody
              v-if="!(is_loading || data?.length === 0)"
              class="divide-y divide-gray-200 bg-white"
            >
              <template v-if="!group_by && !group_by_function">
                <TableRow
                  :data="getRowsData"
                  :additional_row_classes="additional_row_classes"
                  :striped="striped"
                  :columns="columns"
                  :selected_rows="selected_rows"
                  :table_col_order="table_col_order"
                  :frozen_col_id="frozen_col_id"
                  :is_pagination_enabled="is_pagination_enabled"
                  :is_vueform_element_enabled="is_vueform_element_enabled"
                  :show_column_borders="show_column_borders"
                >
                  <template v-for="col_id in table_col_order" #[col_id]="col_data">
                    <slot :name="col_id" :data="col_data.data" />
                  </template>
                  <template v-for="row in getInfoSlotAvailableRows(getRowsData)" #[`row_info_${row.original.uid}`]="{ data }">
                    <slot v-if="$slots[`row_info_${row.original.uid}`]" :name="`row_info_${row.original.uid}`" :data="data" />
                  </template>
                  <template #rowLoader>
                    <slot name="rowLoader" />
                  </template>
                </TableRow>
              </template>
              <template v-else>
                <template v-for="(value, name, index) in getRowsData" :key="index">
                  <tr class="border-0 bg-white cursor-pointer" :class="{ 'sticky z-2': sticky_group_label }" :style="sticky_group_label ? { top: `${group_header_top}px` } : {}" @click="show_group[name] = !show_group[name]">
                    <td class="table-cell text-gray-900 border-b border-x border-gray-200" :colspan="get_visible_columns.length">
                      <div class="my-1 mx-4 first:mt-3 last:mb-3 ">
                        <div class="flex items-center" :class="grouping_row_classes">
                          <div class="mx-2 text-base">
                            <slot v-if="show_group[name]" name="group-icon-close">
                              <IconHawkMinusCircle />
                            </slot>
                            <slot v-else name="group-icon-open">
                              <IconHawkPlusCircle />
                            </slot>
                          </div>
                          <slot name="group-label" :data="{ value: name, count: value.rows.length, data: value }">
                            <div class="text-sm font-semibold">
                              <span class="capitalize mr-1">{{ name }}</span> ({{ value.rows.length }} rows)
                            </div>
                          </slot>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <TableRow
                    :data="value.rows"
                    :additional_row_classes="[additional_row_classes, show_group[name] ? 'table-row' : 'hidden']"
                    :columns="columns"
                    :striped="striped"
                    :table_col_order="table_col_order"
                    :frozen_col_id="frozen_col_id"
                    :arrows="arrows"
                    :selected_rows="selected_rows"
                    :is_vueform_element_enabled="is_vueform_element_enabled"
                    :is_pagination_enabled="is_pagination_enabled"
                    :show_column_borders="show_column_borders"
                  >
                    <template v-for="col_id in table_col_order" #[col_id]="col_data">
                      <slot :name="col_id" :data="col_data.data" />
                    </template>
                    <template v-for="row in getInfoSlotAvailableRows(value.rows)" #[`row_info_${row.original.uid}`]="{ data }">
                      <slot v-if="row.original.uid && $slots[`row_info_${row.original.uid}`]" :name="`row_info_${row.original.uid}`" :data="data" />
                    </template>
                    <template #rowLoader>
                      <slot name="rowLoader" />
                    </template>
                  </TableRow>
                </template>
              </template>
            </tbody>
            <tfoot v-if="show_footer" class="sticky bottom-0 z-3">
              <tr
                v-if="footer_type.includes('column')"
                class="h-11 bg-gray-100"
              >
                <th
                  v-for="col_id in get_visible_columns"
                  :id="col_id"
                  :key="col_id"
                  :style="{
                    ...((col_id === frozen_col_id) && { position: 'sticky', left: 0, zIndex: 1, ...(scroll_x >= 40 && { filter: 'drop-shadow(16px 0 16px rgb(0 0 0 / 0.05))' }) }),
                  }"
                  class="px-6 py-3 text-left border-y border-gray-200 text-gray-800 bg-gray-100 first:border-l last:border-r"
                  :class="{ 'border-r': show_column_borders }"
                >
                  <div v-if="!['select', 'context_menu'].includes(col_id)" class="font-medium text-sm">
                    {{ footer_function(col_id, getDataByColumn[col_id]) }}
                  </div>
                </th>
              </tr>
              <template v-else>
                <tr
                  v-for="(value, name, index) in footer_function(getDataByColumn)"
                  :key="index"
                  class="h-11 bg-gray-100"
                >
                  <th
                    v-for="num in get_visible_columns.length"
                    :id="num"
                    :key="num"
                    class="font-medium py-3 px-6 text-left border-y border-gray-200 text-base text-gray-800 bg-gray-50 first:border-l last:border-r"
                    :class="{ 'border-r': show_column_borders }"
                  >
                    <div v-if="num === get_visible_columns.length - (get_visible_columns.includes('context_menu') ? 2 : (1 + footer_offset))" class="flex gap-1">
                      {{ name }}
                      <slot name="footerAddon" :footer-addon-prop="{ name }" />
                    </div>
                    <div v-else-if="num === get_visible_columns.length - (get_visible_columns.includes('context_menu') ? 1 : (0 + footer_offset))">
                      {{ value }}
                    </div>
                  </th>
                </tr>
              </template>
            </tfoot>
          </table>
          <div
            v-if="is_loading || is_loading_on_scroll || data?.length === 0" class="min-w-full rounded-b-lg flex justify-center items-center hawk_table_loader_no_data_container" :style="{
              width: `${(data?.length === 0 ? table_width : null) || table.getLeftTotalSize()}px`, // take total table width if no data
            }"
            :class="[is_loading || is_loading_on_scroll ? 'border border-t-0 border-gray-200' : '', is_skeleton_loading_enabled ? '' : 'h-96']"
          >
            <template v-if="is_loading || is_loading_on_scroll">
              <slot name="tableLoader">
                <table
                  v-if="is_skeleton_loading_enabled"
                  :style="{
                    width: `${table.getLeftTotalSize()}px`,
                  }"
                  class="min-w-full h-full divide-y divide-gray-200 border-separate border-spacing-0"
                >
                  <tr v-for="row in pagination_state.pageSize" :key="`${row}_row`" class="group cursor-pointer hover:bg-gray-100 border-y ">
                    <td
                      v-for="col in skeleton_visible_columns" :key="`${col}_data`"
                      class="px-6 py-6 w-[20px] border-b text-left text-sm font-normal text-gray-800 relative bg-white group-hover:bg-gray-100"
                    >
                      <HawkSkeleton custom_classes="!w-32 py-2" />
                    </td>
                  </tr>
                </table>

                <HawkLoader v-else />
              </slot>
            </template>
            <template v-else>
              <tr
                class="h-96 min-w-full flex justify-center rounded-b-lg items-center border border-t-0 border-gray-200 hawk_table_no_data" :style="{
                  width: `${table.getLeftTotalSize()}px`,
                }"
              >
                <slot name="noData">
                  <td> {{ $t('No data present') }} </td>
                </slot>
              </tr>
            </template>
          </div>
          <div
            v-if="!(is_loading || is_loading_on_scroll || data?.length === 0)"
            class="min-w-full"
            :style="{
              width: `${table_width ? table_width : table.getLeftTotalSize()}px`,
            }"
          >
            <div
              v-if="is_pagination_enabled" :style="{ width: pagination_width ? `${pagination_width}px` : '100%' }"
              class="sticky rounded-b-lg left-0 z-[1] flex items-center border-x border-b border-gray-200 justify-between flex-wrap px-6 min-h-16 border-t bg-gray-50"
            >
              <div class="flex items-center py-4">
                <span className="flex items-center gap-1 mr-8">
                  <div class="text-sm font-medium">
                    {{ (table.getState().pagination.pageIndex * table.getState().pagination.pageSize) + 1 }} -
                    {{ getPaginationLimit }}
                  </div>
                  <div class="text-sm font-normal text-gray-400">
                    of {{ pagination_config.totalRows || data?.length }} items
                  </div>
                </span>
                <HawkMenu :items="getPageSizeOptions">
                  <template #trigger>
                    <div class="flex items-center text-sm font-semibold">
                      {{ pagination_state.pageSize }}
                      <IconHawkChevronDown class="text-xl text-gray-500 mx-1" />
                    </div>
                  </template>
                </HawkMenu>
                <span class="text-sm text-gray-400 mb-1">per page</span>
              </div>
              <nav
                class="isolate inline-flex -space-x-px rounded-md shadow-sm h-9"
                aria-label="Pagination"
              >
                <button
                  :disabled="!table.getCanPreviousPage()"
                  class="relative inline-flex items-center rounded-l-md w-10 border border-gray-300 bg-white p-2 hover:bg-gray-50 focus:z-20"
                  @click="updatePagination('prev')"
                >
                  <IconHawkArrowLeft class="text-lg text-gray-900 disabled:text-gray-300" />
                </button>
                <button
                  v-for="(value, name, index) in getPages"
                  :key="index"
                  aria-current="page"
                  :class="[
                    value.selected
                      ? 'bg-gray-100 z-30 hover:border'
                      : 'bg-white',
                  ]"
                  class="relative inline-flex items-center text-center border border-gray-300 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:z-20"
                  :style="{
                    minWidth: '40px',
                  }"
                  @click="!value.breakView && updatePagination('pageIndex', value.content - 1)"
                >
                  <div class="min-w-full px-2">
                    {{ value.breakView ? "..." : value.content }}
                  </div>
                </button>
                <button
                  :disabled="(pagination_state.pageIndex + 1) * pagination_state.pageSize >= pagination_config.totalRows"
                  class="relative inline-flex items-center rounded-r-md w-10 border border-gray-300 bg-white p-2 hover:bg-gray-50 focus:z-20"
                  @click="updatePagination('next')"
                >
                  <IconHawkArrowRight class="text-lg text-gray-900 disabled:text-gray-300" />
                </button>
              </nav>
            </div>
          </div>
        </template>
        <HawkLoader v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .sortable-chosen, .sortable-ghost {
    opacity: 1 !important;
  }
  #hawk_table {
    table {
      border-collapse: separate;
      border-spacing: 0;
      &.no-border-radius  :last-child  tr:last-child  {
        th:first-child,td:first-child{
          border-bottom-left-radius: 0px !important;
        }
        th:last-child,td:last-child{
          border-bottom-right-radius: 0px !important;
        }
      }
      :first-child  tr:first-child  {
        th:first-child,td:first-child{
           border-top-left-radius: 8px;
        }
        th:last-child,td:last-child{
           border-top-right-radius: 8px;
        }
      }
      &.no_pagination :last-child  tr:last-child  {
        th:first-child,td:first-child{
           border-bottom-left-radius: 8px;
        }
        th:last-child,td:last-child{
           border-bottom-right-radius: 8px;
        }
      }
    }
    :deep(.resizer) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 5px;
      background: rgba(0, 0, 0, 0.5);
      cursor: col-resize;
      user-select: none;
      touch-action: none;
      visibility: hidden;
      &.isResizing{
      background: blue;
      opacity: 1;
      }
    }

    .hawk_table_menu_item:hover {
      .move, .pin {
        visibility: visible;
      }
    }
    :deep(.is_draggable) {
      .handle {
        visibility: hidden;
      }
      &:hover {
        .handle {
          visibility: visible;
        }
      }
    }
    @media (hover: hover) {
      :deep(.resizer) {
        visibility: hidden;
      }
      *:hover > :deep(.resizer) {
        visibility: visible;
      }

    }
  }
</style>
