<script setup>
import { useRoute, useRouter } from 'vue-router';

import { useModal } from 'vue-final-modal';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { usePusherStore } from '~/common/stores/pusher.store.js';

import TasksListView from '~/tasks/pages/tasks/tasks-list-view.vue';
import TasksKanbanView from '~/tasks/pages/tasks/tasks-kanban-view.vue';
import TasksCalendarView from '~/tasks/pages/tasks/calendar-view/tasks-calendar-view.vue';

import StandardTaskForm from '~/tasks/components/molecule/task-form/standard-task-form.vue';
import TaskFilters from '~/tasks/components/organisms/tasks-filters.vue';

import SearchAndFilterRow from '~/tasks/components/molecule/search-and-filter-row.vue';

import ConvertToSubtask from '~/tasks/components/organisms/convert-to-subtask.vue';
import DependencyPopup from '~/tasks/components/organisms/dependency-popup.vue';
import DuplicateTask from '~/tasks/components/organisms/duplicate-task.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
  table_options: {
    /*
      {
        column_config: Object with key as column id and value as it's configuration,
        table_height: Number,
        additional_table_classes: String,
        additional_row_classes: String,
        disable_resize: Boolean,
        no_table_wrapper: Boolean,
        hide_context_menu: Boolean,
      }
    */
    type: Object,
    default: () => ({}),
  },
  store_key: {
    type: String,
    default: '',
  },
  is_widget: {
    type: Boolean,
    default: false,
  },
  is_compact_view: {
    type: Boolean,
    default: false,
  },
  id: {},
  is_modal_view: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['columnResized']);

const $t = inject('$t');
const $toast = inject('$toast');

const route = useRoute();
const router = useRouter();
const { copy } = useClipboard();

const auth_store = useAuthStore();
const task_store = useTasksStore(props.store_key);

const current_organization = auth_store.current_organization;

/* ---------- STATIC VALUES AND MAPPINGS ---------- */
const views = [
  {
    uid: 0,
    leftSlot: IconHawkTableTwo,
    rightSlot: 'list-view',
    action: 'list-view',
    tooltip_text: $t('List view'),
  },
  {
    uid: 1,
    leftSlot: IconHawkAlignTopArrowTwo,
    rightSlot: 'kanban-view',
    action: 'kanban-view',
    tooltip_text: $t('Kanban view'),
  },
  {
    uid: 2,
    leftSlot: IconHawkCalendar,
    rightSlot: 'calendar-view',
    action: 'calendar-view',
    tooltip_text: $t('Calendar view'),
  },
];

const view_map = {
  list: {
    key: 0,
    component: TasksListView,
  },
  kanban: {
    key: 1,
    component: TasksKanbanView,
  },
  calendar: {
    key: 2,
    component: TasksCalendarView,
  },
};

/* ---------- REACTIVE VALUES ---------- */
const view_component = ref(null);
const state = reactive({
  show_search: true,
  is_loading: false,
  show_view: true,
});

const data = computed(() => task_store.tasks(props?.options?.include_subtasks || false));
const has_filter_applied = computed(() => task_store.search_key || task_store.filters_count);
const has_data = computed(() => data.value.length || task_store.active_view === 'calendar');
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value && !props.is_widget);
const view_component_props = computed(() => {
  if (task_store.active_view === 'list')
    return {
      options: props.options,
      table_options: props.table_options,
    };
});

/* ---------- LIFECYCLE HOOKS ---------- */
onMounted(async () => {
  state.is_loading = true;
  await view_component.value.getTasks({}, true, false);
  state.is_loading = false;
});
watch(() => route.params.asset_id, async (newValue) => {
  if (newValue)
    await setView(task_store.active_view);
});
watchEffect(() => {
  const pusher_store = usePusherStore();
  if (props.options?.set_pusher !== false && pusher_store.is_initialized)
    task_store.subscribe_pusher({
      organization: current_organization?.uid,
      ...(route.params.asset_id ? { asset: route.params.asset_id } : {}),
    });
}, { flush: 'post' });

/* ---------- METHODS ---------- */
async function reRender() {
  state.show_view = false;
  await nextTick();
  state.show_view = true;
}
async function setView(view) {
  task_store.$patch((state) => {
    state.tasks_map = {};
    state.filters = {};
    state.search_key = '';
    state.last_read = null;
  });
  state.is_loading = true;
  task_store.set_active_view(view);
  await reRender();
  await view_component.value.getTasks({}, true, false);
  task_store.task_track_events('View changed', {}, '', ['view']);
  state.is_loading = false;
}

function viewTaskDetailsHandler(id) {
  const task = btoa(JSON.stringify({
    id,
    store_key: props.store_key,
  }));
  router.replace({
    ...route,
    query: {
      ...route.query,
      task,
    },
  });
}

/* --------- CREATE TASK --------- */
const { open: openTaskForm, close: closeTaskForm, patchOptions: patchTaskForm } = useModal({
  component: StandardTaskForm,
});
function createTask(task_data = {}) {
  patchTaskForm({
    attrs: {
      task_store,
      task_data,
      allow_multi_task_create: true,
      onClose() {
        closeTaskForm();
      },
      onTaskCreated() {
        if (view_component.value.refreshView)
          view_component.value.refreshView();
      },
    },
  });
  openTaskForm();
}

/* --------- TASK FILTERS AND SEARCH --------- */
const { open: openFiltersPopup, close: closeFiltersPopup } = useModal({
  component: TaskFilters,
  attrs: {
    task_store,
    asset_id: route.params.asset_id,
    onClose() {
      closeFiltersPopup();
    },
    async onFilter() {
      state.is_loading = true;
      await view_component.value.getTasks({}, false, false);
      state.is_loading = false;
    },
  },
});

async function clearFilters() {
  state.is_loading = true;
  task_store.set_filters({ reset: true });
  await view_component.value.getTasks({}, false, false);
  state.is_loading = false;
}

async function searchTasks(value) {
  state.is_loading = true;
  await view_component.value.searchTasks(value);
  state.is_loading = false;
}

/* --------- CONTEXT MENU EVENT HANDLERS --------- */
const { open: openConvertToSubtaskPopup, close: closeConvertToSubtaskPopup, patchOptions: patchConvertToSubtaskPopup } = useModal({
  component: ConvertToSubtask,
});
function convertToSubtaskHandler(task) {
  patchConvertToSubtaskPopup({
    attrs: {
      task_uid: task.uid,
      onClose(success) {
        if (success && view_component.value.refreshView)
          view_component.value.refreshView();
        closeConvertToSubtaskPopup();
      },
    },
  });
  openConvertToSubtaskPopup();
}

function copyTaskURLHandler(task) {
  const encoded_uid = btoa(JSON.stringify({
    id: task.uid,
    store_key: task_store.$id,
  }));
  const task_path = `${window.location.origin}/tasks?task=${encoded_uid}&organization=${current_organization.uid}`;
  copy(task_path);
  $toast({
    title: 'Url copied successfully',
    type: 'success',
    position: 'bottom-right',
  });
}

async function archiveTaskHandler(task) {
  const action = task.archive ? 'unarchived' : 'archived';
  await task_store.archive_tasks([task.uid], !task.archive);
  if (!task.archive)
    task_store.task_track_events('Archived', { mode: 'Single', count: 1 }, task.uid);
  $toast({
    title: `Task ${action} successfully`,
    text: `Your changes have been saved and your task is ${action} from the list`,
    type: 'success',
    position: 'bottom-right',
  });
}

const { open: openDuplicateTaskPopup, close: closeDuplicateTaskPopup, patchOptions: patchDuplicateTaskPopup } = useModal({
  component: DuplicateTask,
});
function duplicateTaskHandler(task) {
  patchDuplicateTaskPopup({
    attrs: {
      task_store,
      task,
      onClose() {
        closeDuplicateTaskPopup();
      },
    },
  });
  openDuplicateTaskPopup();
}

const { open: openDependencyPopup, close: closeDependencyPopup, patchOptions: patchDependencyPopup } = useModal({
  component: DependencyPopup,
});
function addDependencyHandler(task) {
  patchDependencyPopup({
    attrs: {
      task_store,
      task_uid: task.uid,
      onClose() {
        closeDependencyPopup();
      },
      async onUpdate(payload) {
        try {
          await task_store.update_tasks([task.uid], payload);
          const relations = [];
          if (payload.blocked_by.length)
            relations.push('Blocking');
          if (payload.blocking.length)
            relations.push('Waiting');
          if (payload.linked_uids.length)
            relations.push('Linked');
          task_store.task_track_events('Dependencies updated', { relation: relations.join(', ') }, task.uid);
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
            position: 'bottom-right',
          });
        }
      },
    },
  });
  openDependencyPopup();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function deleteTaskHandler(task) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Task'),
        content: `Are you sure you want to delete ${task.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await task_store.remove_tasks([task.uid]);
            $toast({
              title: 'Task deleted successfully',
              text: 'Your changes have been saved and your task is removed from the list',
              type: 'success',
            });
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

function emitColumnResized(resized_column, columns) {
  emit('columnResized', resized_column, columns);
};

provide('task_store', task_store);
provide('is-template', false);
</script>

<template>
  <div>
    <slot name="top-panel">
      <SearchAndFilterRow
        v-if="state.show_search"
        class="mb-8"
        :filters_count="task_store.filters_count"
        :search="searchTasks"
        :search_key="task_store.search_key"
        @open-filters="openFiltersPopup"
        @clear-filters="clearFilters"
      >
        <template #right>
          <slot name="top-right-controls">
            <div class="flex items-center">
              <hawk-button-group
                v-if="route.name === 'tasks'"
                icon
                size="md"
                class="mr-2"
                :items="views"
                :active_item="view_map[task_store.active_view].key"
                @list-view="setView('list')"
                @kanban-view="setView('kanban')"
                @calendar-view="setView('calendar')"
              />
              <hawk-button
                v-if="auth_store.check_permission('create_tasks', route.params.asset_id)"
                color="primary"
                @click="createTask({})"
              >
                <IconHawkPlusWhite class="text-white h-3" />
                {{ $t('New task') }}
              </hawk-button>
            </div>
          </slot>
        </template>
      </SearchAndFilterRow>
    </slot>
    <div v-if="state.is_loading" class="h-[calc(100vh_-_400px)] flex items-center justify-center">
      <hawk-loader />
    </div>
    <div v-else-if="is_boarding">
      <HawkIllustrations
        type="on-boarding"
        for="tasks"
        :has_permission="auth_store.check_permission('create_tasks', route.params.asset_id)"
        :is_create_indicator="auth_store.check_permission('create_tasks', route.params.asset_id)"
      />
    </div>
    <div v-else-if="!has_data">
      <div v-if="is_widget" class="my-6">
        <HawkIllustrations variant="mini" type="no-data" for="tasks" />
      </div>
      <HawkIllustrations v-else type="no-results" />
    </div>

    <!-- Show view only when has data or is calendar view -->
    <div v-if="state.show_view" v-show="has_data && !state.is_loading">
      <component
        :is="view_map[task_store.active_view].component"
        :id="id"
        ref="view_component"
        :is_compact_view="is_compact_view"
        :is_widget="is_widget"
        :is_modal_view="is_modal_view"
        v-bind="view_component_props"
        @add-dependencies="addDependencyHandler"
        @archive-task="archiveTaskHandler"
        @convert-to-subtask="convertToSubtaskHandler"
        @copy-task="copyTaskURLHandler"
        @delete-task="deleteTaskHandler"
        @duplicate-task="duplicateTaskHandler"
        @show-search="$event => state.show_search = $event"
        @view-task="viewTaskDetailsHandler"
        @column-resized="emitColumnResized"
      >
        <!-- This exposes slots from Tasks List View, Check task-list-widget.vue for usage -->
        <template #task_name="task">
          <slot name="task_name" :task="task" />
        </template>
        <template #context_menu="task">
          <slot name="context_menu" :task="task" />
        </template>
      </component>
    </div>
  </div>
</template>
