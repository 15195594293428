<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep, isArray, isNil } from 'lodash-es';
import { onBeforeUnmount } from 'vue';
import { useModal } from 'vue-final-modal';
import { useToast } from 'vue-toastification';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';
import SaveViewToastTemplate from '~/common/components/organisms/hawk-filters/save-view-toast-template.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useViewConfiguration } from '~/common/composables/useViewConfiguration.js';
import { getPageNameByRouteName } from '~/common/utils/common.utils';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  view_configuration: {
    type: Object,
    default: null,
  },
  default_filters: {
    type: Array,
    default: () => ([]),
  },
  filter_options: {
    type: Object,
    default: () => ({}),
  },
  field_modal_options: {
    type: Object,
    default: () => ({}),
  },
  can_reset: {
    type: Boolean,
    default: true,
  },
  display_filters: {
    type: Object,
    default: () => {},
  },
  is_save_view_toast: {
    type: Boolean,
    default: false,
  },
  save_view_toast_texts: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['apply']);

const toastification = useToast();

const { getFormattedDate } = useFamConstants();
const { $t, route } = useCommonImports();
const { display_filters, active_view, setView, updateView } = useViewConfiguration(props.view_configuration || {});

const $track_event = inject('$track_event');
const state = reactive({
  filters: {},
  filter_resetting: 0,
  is_loading: false,
});

const configure_filters_popup = useModal({
  component: HawkFieldsSelector,
  attrs: {
    onClose() {
      configure_filters_popup.close();
    },
  },
});

const is_value_available = computed(() => {
  const values = [];
  for (const key in state.filters) {
    const value = state.filters[key].value ?? '';
    if (isArray(value) ? value.length : String(value)?.length)
      values.push(key);
  }
  return !!values?.length;
});

const filters = computed(() => {
  const data = Object.values(state.filters).reduce((items, item) => {
    if (!isNil(item.value) && (isArray(item.value) ? item.value.length : String(item.value).length))
      items.push(item);
    return items;
  }, []);
  return getFilters(data);
});

const filters_payload = computed(() => {
  return filters.value.reduce((filters_obj, item) => {
    filters_obj[item.field] = item.value;
    return filters_obj;
  }, {});
});

const filters_to_display = computed(() => {
  if (props.view_configuration) {
    const filters = display_filters.value?.reduce((items, filter) => {
      if (props.filter_options[filter?.field])
        items.push(props.filter_options[filter.field]);
      return items;
    }, []);
    if (filters?.length)
      return filters;
    else return props.default_filters;
  }
  return props.display_filters;
});

function resetFilters(is_emit = true) {
  state.filters = {};
  if (is_emit)
    emit('apply', filters.value);
  showToastToSaveView();
  state.filter_resetting++;
}

function updateFilter(filter_config, e) {
  state.filters[filter_config.uid] = {
    ...e,
    ...(props.view_configuration ? { data_type: filter_config.data_type } : {}),
    type: filter_config.type,
  };
  const view = getPageNameByRouteName(route.name);
  if (view)
    $track_event('Filtered', { view });
  emit('apply', filters.value);
  showToastToSaveView();
}

function getFilters(data) {
  const updated_rules = cloneDeep(data);
  return updated_rules?.map((rule) => {
    if (rule.operator_option)
      rule.value = getFormattedDate(rule.operator_option) || rule.value;
    return rule;
  });
}

function openConfigureModal() {
  configure_filters_popup.patchOptions({
    attrs: {
      items: Object.values(props.filter_options),
      selected_items: filters_to_display.value,
      max_selected_items: 5,
      min_selected_items: 1,
      update: async (data) => {
        await updateView({
          display_filters: data,
        });
      },
      ...props.field_modal_options,
    },
  });
  configure_filters_popup.open();
}

function showToastToSaveView() {
  if (!props.view_configuration?.modify_permissions || !props.is_save_view_toast)
    return;
  toastification({
    component: SaveViewToastTemplate,
    props: {
      _t: $t,
      save_view_toast_texts: props.save_view_toast_texts || {},
      save_view: async () => {
        try {
          await updateView({
            display_filters: display_filters.value.map(df => ({
              uid: df.field,
              default_value: state.filters[df.field],
            })),
          });
        }
        catch (err) {
          logger.log(err);
        }
      },
    },
    listeners: {
      onClose: () => {
        toastification.dismiss('saveChangesToast');
      },
    },
  }, {
    id: 'saveChangesToast',
    position: 'bottom-right',
    timeout: false,
    closeOnClick: false,
    toastClassName: '!bg-white !p-0',
    bodyClassName: '!bg-white',
    shareAppContext: true,
  });
}
function setSavedViewValues() {
  filters_to_display.value?.forEach((item) => {
    const default_value = display_filters.value?.find(_item => _item.field === item.uid)?.default_value;
    if (default_value)
      state.filters[item.uid] = default_value;
  });
  setTimeout(() => {
    emit('apply', filters.value);
  }, 0);
}
onBeforeUnmount(() => {
  toastification.dismiss('saveChangesToast');
});
onMounted(async () => {
  try {
    state.is_loading = !active_view.value;
    await setView();
    props.is_save_view_toast && setSavedViewValues();
    state.is_loading = false;
  }
  catch {
    state.is_loading = false;
  }
});

defineExpose({ filters, getFilters, filters_payload, resetFilters });
</script>

<template>
  <div v-if="!state.is_loading" class="flex gap-2 flex-wrap items-center">
    <div v-for="filter_config in filters_to_display" :key="filter_config.uid || filter_config.id">
      <HawkQuickFilters
        v-bind="filter_config"
        :default_value="state.filters[filter_config.uid]"
        :filter_resetting="state.filter_resetting"
        @apply="e => updateFilter(filter_config, e)"
      />
    </div>
    <HawkButton v-if="can_reset && is_value_available" icon size="xxs" color="gray" rounded @click="resetFilters()">
      <IconHawkX />
    </HawkButton>
    <HawkButton
      v-else-if="view_configuration?.modify_permissions"
      v-tippy="$t('Filter configurations')"
      type="text"
      icon @click="openConfigureModal"
    >
      <IconHawkSettingsOne />
    </HawkButton>
  </div>
  <div v-else />
</template>
